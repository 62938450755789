import Vue from 'vue';
import Vuex from 'vuex';

import loader from './states/loader';
import sale from './states/sale';
import user from './states/user';


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        openMenu: false,
        openAccountMenu: false,
    },
    mutations: {
        toggleMenu: function (state, open) {
            state.openMenu = open;
        },
        toggleAccountMenu: function (state, open) {
            state.openAccountMenu = open;
        },
    },
  getters: {
  },
  actions: {
  },
  modules: {
      loader: loader,
      sale: sale,
      user: user,
  }
})
