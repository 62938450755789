<template>
    <div class="wide-box">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "WideBox"
}
</script>

<style scoped lang="scss">
    .wide-box{
        background: #FFFFFFEB;
        width: 100%;
        max-width: 100%;
        border-radius: 8px;
        box-shadow: 0px 4px 12px #00000018;
        padding: 40px 94px 48px;
        margin: auto;
        overflow: hidden;
        @media screen and (max-width: 575px){
            padding: 40px 16px 48px;
        }
    }
</style>
