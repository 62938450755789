import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    //mode: 'hash',
    saveScrollPosition: false,
    base: window.baseUrl,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return window.scrollTo({
                top: document.querySelector(to.hash).offsetTop - 80,
                behavior: 'smooth'
            })
        }
        return {x: 0, y: 0}
    },
    routes
});

router.beforeEach( (to, from, next) => {
    if(to.meta.access === 'all'){
        next();
    } else if(to.meta.access === 'private'){
        store.getters.isLoggedIn ? next() : next({name: 'login'});
    } else if (store.getters.isLoggedIn) {
        next({name: 'home'});
    } else {
        next();
    }

    if (store.getters.isLoggedIn) {
        store.dispatch('getUser');
    }
    store.commit('toggleMenu', false);
    document.querySelector('body').classList.toggle('overflow-hidden', false);
});

export default router;

