<template>
  <div id="app">
      <transition name="fade" mode="out-in">
          <router-view />
      </transition>
      <div class="bg-gray-main">
          <div class="container"></div>
      </div>
      <loader-component v-if="isLoading" />
      <cookie-component></cookie-component>
  </div>
</template>

<style lang="scss">
    @import "src/styles/style";
    .bg-gray-main{
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100vh;
        z-index: -1;
        .container{
            background: #E5EAF0;
            height: 100%;
        }
    }
</style>
<script>
import LoaderComponent from "@/components/LoaderComponent";
import CookieComponent from "@/components/CookiesComponent.vue";
export default {
    components: {CookieComponent, LoaderComponent},
    computed:{
        isLoading: function (){
            return this.$store.getters.isLoading;
        }
    }
}
</script>
