<template>
    <div class="layout">
        <navigation-component class="mb-5" />
        <transition name="fade" mode="out-in">
            <div v-if="isLoggedIn && shouldAcceptRegulations" class="container mw-1136 f24">
                <wide-box class="text-center">
                    <p class="py-5">Zawartość strony będzie dostępna po zaakceptowaniu nowego regulaminu.</p>
                </wide-box>
            </div>
            <router-view v-else/>
        </transition>
        <transition name="fade" mode="out-in">
            <popup-slot-component v-if="isLoggedIn && shouldAcceptRegulations" :hide-button="true" :hide-close-button="true">
                <h2 class="text-uppercase text-center c-blue-dark f24 fw-bold mb-4 mt-3">
                    Potwierdź akceptację regulaminu
                </h2>
                <p class="mb-3" v-html="agreement_1_text"></p>
                <p class="mb-3">
                    <a @click="saveApprove" href="#" class="btn ">Potwierdzam</a>
                </p>
            </popup-slot-component>
        </transition>
    </div>
</template>

<script>


import NavigationComponent from "@/components/NavigationComponent";
import PopupSlotComponent from "@/components/PopupSlotComponent.vue";
import WideBox from "@/components/WideBox.vue";
import {mapActions} from "vuex";
export default {
    name: "PrivateLayout", components: {
        WideBox,
        PopupSlotComponent,
        NavigationComponent

    },
    data(){
        return {
            publicPath: process.env.BASE_URL,
            agreement_1_text: 'Zapoznałam/em się z regulaminem programu „Salesman Club” i akceptuję jego warunki.'
        }
    },
    mounted() {
        this.setRegulation();
    },
    computed: {
        shouldAcceptRegulations(){
            return this.$store.getters.shouldAcceptRegulations;
        },
        regulation_file(){
            let file = this.publicPath;
            if(this.user.car_type_guid === 'f1c5560c-feb7-11ec-99b5-00090faa0001'){ //pojazdy ciężkie
                file += 'files/regulamin_pojazdy_ciezkie.pdf?v=20240423'
            } else {
                file += 'files/regulamin_pojazdy_srednie_lekkie.pdf?v=20240423';
            }
            return file;
        },
        isLoggedIn: function () {
            return this.$store.getters.isLoggedIn;
        },
        user(){
            return this.$store.getters.user;
        }
    },
    watch: {
        regulation_file(){
            this.setRegulation();
        }
    },
    methods:{
        setRegulation(){
            this.agreement_1_text = 'Zapoznałam/em się z&nbsp;<a target="_blank" class="c-blue-dark text-decoration-underline fw-bold" href="' + this.regulation_file + '">regulaminem</a> programu „Salesman Club” i&nbsp;akceptuję jego warunki.'
        },
        ...mapActions(['acceptRegulation']),
        saveApprove: async function () {
            //this.$store.state.user.user.agreement_2023 = true;
            await this.acceptRegulation({
                agreement_1: 1,
                text_agreement_1: 'Zapoznałam/em się z regulaminem programu „Salesman Club” i akceptuję jego warunki.'
            })
                .then(() => {
                    this.$store.dispatch('getUser');
                })
                .catch(() => {

                });
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
