<template>
  <div class="popup-wrapper">
    <div
      class="popup radius d-flex flex-column justify-content-between"
      :class="{ 'pb-0': hideButton }"
    >
      <span @click="$emit('onClick')" :class="{ 'd-none': hideCloseButton }" class="popup__close"></span>
      <div class="mb-3">
        <slot></slot>
      </div>
      <div :class="{ 'd-none': hideButton }">
        <a class="btn" href="#" @click.prevent="$emit('onClick')">OK</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupSlotComponent",
  data: () => {
    return {};
  },
  props: {
    hideButton: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 10;
  display: block;
  overflow: auto;
  text-align: center;
}
.popup {
  width: 800px;
  min-height: 200px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  background: white;
  padding: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-width: calc(100% - 1rem);
  @media screen and (max-width: 767px) {
    padding: 1rem;
  }
  @media screen and (max-width: 575px) {
    padding: 0.75rem;
  }
}
.popup__close {
  background: url("@/assets/img/btn_close.svg") no-repeat;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
</style>
