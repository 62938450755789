//import router from "@/router";

const sale = {
    state: {
        categories: [],
        products: [],
        saleWasAdded: false,
        sales: [],
        rulesProducts: [],
    },
    mutations: {
        setCategories(state, payload) {
            state.categories = payload;
        },
        setProducts(state, payload) {
            state.products = payload;
        },
        setSaleWasAdded(state, payload) {
            state.saleWasAdded = payload;
        },
        setSales(state, payload) {
            state.sales = payload;
        },
        setRulesProducts(state, payload) {
            state.rulesProducts = payload;
        },
    },
    actions: {
        getCategories: function (context) {
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.get('/category/bonus-conditions')
                    .then((response) => {
                        context.commit('decreaseLoader');
                        context.commit('setCategories', response.data.data);
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('decreaseLoader');
                        // if (e.response.status === 401) {
                        //
                        // }
                        reject(e);
                    })
            })
        },
        getProducts: function (context) {
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.get('/product')
                    .then((response) => {
                        context.commit('decreaseLoader');
                        context.commit('setProducts', response.data.data);
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('decreaseLoader');
                        // if (e.response.status === 401) {
                        //
                        // }
                        reject(e);
                    })
            })
        },
        addSale: function (context, payload) {
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.post('/sale/store', payload)
                    .then((response) => {
                        context.commit('decreaseLoader');
                        context.commit('setSaleWasAdded', true);
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('decreaseLoader');
                        // if (e.response.status === 401) {
                        //
                        // }
                        reject(e);
                    })
            })
        },
        getSales: function (context) {
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.get('/sale')
                    .then((response) => {
                        context.commit('decreaseLoader');
                        context.commit('setSales', response.data.data);
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('decreaseLoader');
                        // if (e.response.status === 401) {
                        //
                        // }
                        reject(e);
                    })
            })
        },
        getRulesProducts: function (context) {
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.get('/product')
                    .then((response) => {
                        context.commit('decreaseLoader');
                        context.commit('setRulesProducts', response.data.data);
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('decreaseLoader');
                        // if (e.response.status === 401) {
                        //
                        // }
                        reject(e);
                    })
            })
        },
    },
    getters: {
        getCategories: function (state) {
            return state.categories;
        },
        getProducts: function (state) {
            return state.products;
        },
        getSaleWasAdded: function (state) {
            return state.saleWasAdded;
        },
        getSales: function (state) {
            return state.sales;
        },
        getRulesProducts: function (state) {
            return state.rulesProducts;
        },
    }
}

export default sale;
