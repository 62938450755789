import router from "../../router";
import axios from 'axios'

const payload = {
    first_name: '-',
    last_name: '-',
    email: '',
    phone_number: '-',
    street: '-',
    house_number: '-',
    flat_number: '-',
    town: '-',
    postal_code: '-',
    address_array:{},
    other_corresponding_address: 1,
    corresponding_address: '-',
    corresponding_street: '-',
    corresponding_house_number: '-',
    corresponding_flat_number: '-',
    corresponding_town: '-',
    corresponding_postal_code: '-',
    corresponding_address_array: {},
    group_id: '',
    car_type_id: '',
    car_type_guid: '',
    seller_type_id: '',
    dealer_id: '',
    form_of_employment_id: '',
    tax_office_id: '',
    pesel: '-',
    payout_form: '',
    account_number: '',
    prepaid: '',
    agreement_1: false,
    agreement_2: false,
    agreement: false,
    can_add_sale: false,
}

const user = {
    state: {
        isLoggedIn: !!sessionStorage.getItem('access_token') || !!localStorage.getItem('access_token'),
        homeData:{
            suma1: 1000
        },
        user: payload,
    },

    mutations: {
        userUpdate: function (state, payload) {
            state.user = {...state.user, ...payload};
        },
        setLoggedIn(state, payload) {
            state.isLoggedIn = payload;
        },
        setHomeData(state, payload) {
            state.homeData = payload;
        },
    },

    actions: {
        login: function (context, payload) {
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.post('login', payload)
                    .then((response) => {
                        if(payload.remember_me){
                            localStorage.setItem('access_token', response.data.data.token);
                        } else {
                            sessionStorage.setItem('access_token', response.data.data.token);
                        }
                        context.commit('decreaseLoader');
                        context.commit('setLoggedIn', true);
                        context.dispatch('getUser');
                        router.go(history && history.length > 2 ? -1 : {name: 'home'});
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('decreaseLoader');
                        if (e.response.status === 401) {
                            if (sessionStorage.getItem('access_token')) {
                                sessionStorage.removeItem('access_token')
                            }
                            if (localStorage.getItem('access_token')) {
                                localStorage.removeItem('access_token')
                            }
                        }
                        reject(e);
                    })
            })
        },
        registration: function (context, payload) {
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.post('register', payload)
                    .then((response) => {
                        context.commit('decreaseLoader');
                        router.replace({name: 'registration-confirmation'});
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('decreaseLoader');
                        // if (e.response.status === 401) {
                        //
                        // }
                        reject(e);
                    })
            })
        },
        logout: async function (context) {
            await window.api.post('/logout')
                .then(() => {

                })
                .catch(() => {});
                sessionStorage.clear();
                localStorage.clear();
                delete axios.defaults.headers.common['Authorization'];
                context.commit('setLoggedIn', false);
                router.push({name: 'login'});
        },
        getUser: async function (context) {
            await window.api.get('/user/me')
                .then((response) => {
                    let payload = response.data.data;
                    context.commit('userUpdate', payload);
                    context.commit('setHomeData', {quizzes: payload.quizzes, bonuses: payload.bonuses});

                    if(router.history.current.name !== 'paymentData' && context.getters.shouldFillPayment){
                        router.replace({name: 'paymentData'});
                    }
                })
                .catch((e) => {
                    if (e.response !== undefined && e.response.status === 401) {
                        if (sessionStorage.getItem('access_token')) {
                            sessionStorage.removeItem('access_token')
                        }
                        if (localStorage.getItem('access_token')) {
                            localStorage.removeItem('access_token')
                        }
                    }
                });
        },
        setPayoutForm: function (context, payload) {
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.post('user/set-payout-form', payload)
                    .then((response) => {
                        context.commit('decreaseLoader');
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('decreaseLoader');
                        // if (e.response.status === 401) {
                        //
                        // }
                        reject(e);
                    })
            })
        },
        acceptRegulation: function (context, payload) {
            context.commit('increaseLoader');
            return new Promise((resolve, reject) => {
                window.api.post('user/accept-rule', payload)
                    .then((response) => {
                        context.commit('decreaseLoader');
                        resolve(response);
                    })
                    .catch((e) => {
                        context.commit('decreaseLoader');
                        // if (e.response.status === 401) {
                        //
                        // }
                        reject(e);
                    })
            })
        },
    },

    getters: {
        user: function (state) {
            return state.user;
        },
        isLoggedIn: function (state) {
            return !!state.isLoggedIn;
        },
        shouldFillPayment: function (state) {
            //musi być rozbite, ponieważ w przypadku 0 jest dodatkowy warunek
            return state.user.payout_form === '' || state.user.payout_form === null || (state.user.payout_form === 0 && !state.user.account_number);
        },
        shouldAcceptRegulations: function (state){
          return (state.user.agreement  === 0 || state.user.agreement === undefined);
        },
        homeData: function (state) {
            return state.homeData;
        },
    }
}

export default user;
