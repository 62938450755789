import PrivateLayout from "@/views/layouts/PrivateLayout";
import PublicLayout from "@/views/layouts/PublicLayout";

const routes = [
    {path: '/', component: PrivateLayout, children: [{path: '', meta: {access: 'private'}, name: 'home', component: () => import('../views/pages/HomePage')}], },
    {path: '/zasady', component: PrivateLayout, children: [{path: '', meta: {access: 'private'}, name: 'rules', component: () => import('../views/pages/RulesPage')}], },
    //{path: '/konkurs', component: PrivateLayout, children: [{path: '', meta: {access: 'private'}, name: 'contest', component: () => import('../views/pages/ContestPage')}], },
    {path: '/konkurs-wyjazd-do-turynu', component: PrivateLayout, children: [{path: '', meta: {access: 'private'}, name: 'contest-turin', component: () => import('../views/pages/ContestTurinPage')}], },
    {path: '/sprzedaz', component: PrivateLayout, children: [
        {path: '', meta: {access: 'private'}, name: 'sales', component: () => import('../views/pages/SalesPage')},
        {path: '/sprzedaz/dodaj', props: true, meta: {access: 'private'}, name: 'sales-registration', component: () => import('../views/pages/SalesRegistrationPage')},
    ], },
    {path: '/profil/:verify?', component: PrivateLayout, children: [{path: '', meta: {access: 'private'}, name: 'profile', component: () => import('../views/pages/ProfilePage')}], },
    {path: '/dane-do-platnosci', component: PrivateLayout, children: [{path: '', meta: {access: 'private'}, name: 'paymentData', component: () => import('../views/pages/PaymentDataPage')}], },
    {path: '/kontakt', component: PrivateLayout, children: [{path: '', meta: {access: 'all'}, name: 'contact', component: () => import('../views/pages/ContactPage')}], },


    {path: '/logowanie', component: PublicLayout, children: [{path: '', meta: {access: 'public'}, name: 'login', component: () => import('../components/auth/LoginComponent')}], },
    {path: '/rejestracja', component: PublicLayout, children: [{path: '', meta: {access: 'public'}, name: 'registration', component: () => import('../components/auth/RegistrationComponent')}], },
    {path: '/rejestracja-potwierdzenie', component: PublicLayout, children: [{path: '', meta: {access: 'public'}, name: 'registration-confirmation', component: () => import('../components/auth/RegistractionConfirmationComponent')}], },
    {path: '/przypomnij-haslo', component: PublicLayout, children: [{path: '', meta: {access: 'public'}, name: 'forgotPassword', component: () => import('../components/auth/ForgotPasswordComponent')}], },
    {path: '/ustaw-haslo', component: PublicLayout, children: [{path: '', meta: {access: 'public'}, name: 'setPassword', component: () => import('../components/auth/SetPasswordComponent')}], },

    {path: '/l098098098/:token', component: PublicLayout, children: [{path: '', meta: {access: 'all'}, name: 'autologin', component: () => import('../views/pages/AutologinComponent')}], },

    // {path: '/error401', component: PublicLayout, children: [{path: '', meta: {access: 'all'}, name: 'error401', component: () => import('../components/error/Error401')}], },
     {path: '*', component: PublicLayout, children: [{path: '', meta: {access: 'all'}, name: 'error404', component: () => import('../views/pages/Error404Page')}], },
];

export default routes;
