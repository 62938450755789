<template>
    <div class="navigation">
        <div class="container h-100">
            <div
                class="px-0 px-sm-3 px-lg-4 px-xl-5 h-100 d-flex justify-content-between align-items-center flex-wrap flex-sm-nowrap">
                <router-link class="logo mb-2 mb-sm-0" to="/">
                    <img src="@/assets/img/IVECO.png" alt="Iveco"/>
                </router-link>

                <div class="flex-grow-1 mx-4 mx-md-5 mb-2 mb-sm-0">
                    <div class="nav-menu__toggler d-xl-none" :class="{active: openMenu}" @click="toggleMenu">
                        <div class="bar bar-1"></div>
                        <div class="bar bar-2"></div>
                        <div class="bar bar-3"></div>
                    </div>
                    <ul class="menu d-none d-xl-flex"
                        :class="
                            [isLoggedIn ? 'justify-content-center' : 'justify-content-end',
                            {open: openMenu}]
                    ">
                        <template v-if="isLoggedIn">
                            <li>
                                <router-link :to="{name: 'home'}">Moje konto</router-link>
                            </li>
                            <li v-if="user.can_add_sale">
                                <router-link :to="{name: 'sales-registration'}">Dodaj sprzedaż</router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'sales'}">Sprzedaż</router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'rules'}">Zasady</router-link>
                            </li>
                            <li v-if="false">
                                <span class="contest">Konkurs</span>
                                <ul>
<!--                                    <li>-->
<!--                                        <router-link :to="{name: 'contest'}">Akcesoria Iveco</router-link>-->
<!--                                    </li>-->
                                    <li v-if="user.car_type === 'Pojazdy lekkie i średnie'">
                                        <router-link :to="{name: 'contest-turin'}">Wyjazd do Turynu</router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                        <li>
                            <router-link :to="{name: 'contact'}">Kontakt</router-link>
                        </li>
                    </ul>
                </div>
                <router-link class="logo-right mb-2 mb-sm-0" to="/">
                    <img src="@/assets/img/SalesmanClub_logo.svg" alt="Salesman Club"/>
                </router-link>
                <template v-if="isLoggedIn">
                    <div class="bg-black user-panel d-flex flex-row flex-sm-column justify-content-around">
                        <div class="fw-bold d-none d-sm-block">Uczestnik</div>
                        <router-link title="Mój profil" class="profile" :to="{name: 'profile'}">Mój profil</router-link>
                        <a title="Wyloguj" class="logout" href="/logout" @click.prevent="logout">Wyloguj</a>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>


export default {
    name: "NavigationComponent",
    props: {},
    data() {
        return {}
    },
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isLoggedIn;
        },
        openMenu: function () {
            return this.$store.state.openMenu;
        },
        openAccountMenu: function () {
            return this.$store.state.openAccountMenu;
        },
        user: function () {
            return this.$store.getters.user;
        }
    },
    methods: {
        logout: function () {
            this.$store.dispatch('logout');
        },
        toggleMenu: function () {
            this.$store.commit('toggleMenu', !this.openMenu);
            document.querySelector('body').classList.toggle('overflow-hidden', this.openMenu)
        },
    }
}
</script>

<style lang="scss" scoped>
.contest {
    color: #7ABFE8;
    padding: 8px 0;
    margin: 0 16px;
    cursor: default;
}
.navigation {
    background: white;
    box-shadow: 0px 3px 6px #00000010;
    height: 96px;
    @media screen and (max-width: 575px) {
        height: auto;
        padding-top: 0.75rem;
    }
}

.logo {
    @media (max-width: 1440px) {
        max-width: 200px;
    }
    @media (max-width: 399px) {
        max-width: 50%;
    }

    img {
        width: 100%;
    }
}

.logo-right {
    max-width: 20%;

    img {
        width: 100%;
    }
}

.nav-menu__toggler {
    float: right;
    cursor: pointer;

    .bar {
        width: 40px;
        height: 4px;
        background-color: $c-blue-dark;
        transition: .2s;
        margin: 8px 0;
    }

    &.active {
        .bar {
            &-1 {
                transform: translateY(12px) rotate(45deg);
            }

            &-3 {
                transform: translateY(-12px) rotate(-45deg);
            }

            &-2 {
                opacity: 0;
            }
        }
    }
}

.menu {
    padding: 0;
    z-index: 1;
    list-style: none;
    @media screen and (max-width: 1199px) {
        position: absolute;
        width: 100%;
        background: white;
        left: 0px;
        flex-direction: column;
        text-align: center;
        top: 96px;
        box-shadow: 0px 3px 6px #00000010;
        padding: 1rem 0;
    }

    &.open {
        display: flex !important;
    }

    li {
        position: relative;
        text-transform: uppercase;

        a {
            padding: 8px 0;
            margin: 0 16px;
            transition: all 300ms ease 0s;
            border-bottom: 4px solid transparent;
            @media screen and (max-width: 1199px) {
                display: inline-block;
                padding: 2px 0;
                margin: 6px 16px;
            }

            &.router-link-exact-active, &:hover {
                border-bottom: 4px solid $c-blue-dark;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            width: 180px;
            display: none;
            font-size: 12px;
            position: absolute;
            top: 100%;
            left: 0;
            background: #fff;
            border-radius: 4px;
            box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .2);
            @media screen and (max-width: 1199px) {
                width: 100%;
                text-align: center;
                position: relative;
                top: auto;
                left: auto;
                display: block;
                border-radius: 0;
                box-shadow: none;
            }

            li {
                width: 100%;
                padding: 8px 5px;
                display: block;
                margin: 0;
                @media screen and (max-width: 1199px) {
                    padding: 0;
                    margin: 0;
                }

                a {
                    display: block;
                    text-align: start;
                    border-bottom: none;
                }

                a.router-link-exact-active, a:hover {
                    border-bottom: none;
                    color: $c-blue;
                }
            }

        }

        &:hover {
            ul {
                display: block;
                margin: 0;
                padding: 0;
            }
        }
    }
}

.user-panel {
    margin-right: -3.75rem;
    margin-left: 3rem;
    width: 155px;
    height: 100%;
    padding: 0.75rem 1.5rem;
    @media screen and (max-width: 1199px) {
        margin-right: -2.25rem;
    }
    @media screen and (max-width: 991px) {
        margin-right: -1.75rem;
    }
    @media screen and (max-width: 767px) {
        margin-left: 1.5rem;
    }
    @media screen and (max-width: 575px) {
        width: calc(100% + 1.5rem);
        padding: 0.25rem 1rem;
        margin-right: -0.75rem;
        height: auto;
        margin-top: 0.75rem;
        margin-left: -0.75rem;
    }

    a {
        color: white;
        padding-left: 25px;
        display: block;
        margin: 1px 0;
    }

    .profile {
        background: url('~@/assets/img/ico_user.svg') no-repeat 0px center;
    }

    .logout {
        background: url('~@/assets/img/ico_logout.svg') no-repeat 0px center;
    }
}
</style>
