<template>
    <div class="layout">
        <navigation-component class="mb-5" />
        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>
    </div>
</template>

<script>


import NavigationComponent from "@/components/NavigationComponent";
export default {
    name: "PublicLayout", components: {
        NavigationComponent

    },
    mounted() {

    },
}
</script>

<style lang="scss" scoped>

</style>
