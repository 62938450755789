import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueMask from 'v-mask'
import VueGtag from "vue-gtag";

import api from './helpers/api';
window.api = api;

import store from './store'

const moment = require('moment');
require('moment/locale/pl');
Vue.use(require('vue-moment'), {moment});
window.moment = moment;

Vue.config.productionTip = false

let cookiesSettings = localStorage.getItem('cookies');
if (cookiesSettings) {
    cookiesSettings = JSON.parse(cookiesSettings);
    if (cookiesSettings.analytics) {
        Vue.use(VueGtag, {
            config: {
                id: 'G-J0NXDXF3E8'
            }
        })
    }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.use(VueDOMPurifyHTML)
Vue.use(VueMask);
